import { useTheme } from "@mui/material";
import ProgressBarGreenToRed from "../../../../../../../../../../components/complex/ProgressBar/ProgressBarGreenToRed/ProgressBarGreenToRed";
import { InterfaceCreateRiskMeasurementSolution } from "./InterfaceCreateRiskMeasurementSolution";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../../../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../../../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../../../../../../store/ProgressbarInRootSlice";
import TextFieldMultiLine from "../../../../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../../../../../config/Colors";
import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../../../../../../../config/enums/EnumSteps";

function CreateRiskMeasurementSolution(paramsComponent: InterfaceCreateRiskMeasurementSolution) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getRiskMeasurement, setRiskMeasurement] = useState<number>(0);
    const [getDescriptionValue, setDescriptionValue] = useState<string>('');
    const [getClickInButtonOfParentSubmit, setClickInButtonOfParentSubmit] = useState<{ itemClicked: string; time: number; forWhichStep: string; }>({ itemClicked: "", time: -1, forWhichStep: "0.0.0" });
    const callApiForCreateCommentRiskForSolution = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.cottage.buyCottage.steps.addSolutions.stepSolution.stepRiskMeasurement.createRiskMeasurementSolution;
        let bodyObj = {
            // "previous_review": {
            //     "review_type": getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_ct, // or recapservicefundcontrol
            //     "object_id": getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_id,
            // },
            'risk_percentage': getRiskMeasurement,
            'description': getDescriptionValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoThisSolution.id}/solution-risks/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                paramsComponent.callBackReLoad();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (paramsComponent.clickOnButtonInParent.forWhichStep === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`) {
            if (paramsComponent.clickOnButtonInParent.time > 0 && paramsComponent.clickOnButtonInParent.time !== getClickInButtonOfParentSubmit.time) {
                callApiForCreateCommentRiskForSolution();
            }
            setClickInButtonOfParentSubmit(paramsComponent.clickOnButtonInParent);
        }
    }, [paramsComponent.clickOnButtonInParent])
    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'start',
            justifyContent: 'start',
            gap: '1.18rem',
            width: '100%',
        }}>
            <ProgressBarGreenToRed
                callbackChangeValue={function (value: number): void {
                    setRiskMeasurement(value);
                }}
                defaultValue={getRiskMeasurement}
                title={"Risk Of This Solution:"}
            />
            <TextFieldMultiLine
                title={confComps.translate.explanation}
                text={getDescriptionValue}
                onChangeText={setDescriptionValue}
                styleParent={{
                    boxSizing: 'border-box',
                    border: `1px solid ${Colors.theme.blue.border}`,
                    background: Colors.theme.white.white90,
                }}
            />

        </div>
    );
}
export default CreateRiskMeasurementSolution;