import { useTheme } from "@mui/material";
import { isVisibilityProgressBar } from "../../../../../../../../../../store/ProgressbarInRootSlice";
import { InterfaceCreateCheckByTrustiesOfSolution } from "./InterfaceCreateCheckByTrustiesOfSolution";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../../../../../../../config/ConfigCOMP";
import { useState, useEffect } from "react";
import { RequestHandler } from "../../../../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../../../../../../store/profileStateSlice";
import TextFieldMultiLine from "../../../../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../../../../../config/Colors";
import ButtonCancel from "../../../../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonSave from "../../../../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { CelebrationRounded, SentimentVeryDissatisfiedRounded, ThumbDownRounded, ThumbUpRounded } from "@mui/icons-material";
import { InterfaceNTTWorkflowSolution } from "../../../../../../../../../../NTTs/Recap/solution/InterfaceNTTWorkflowSolution";
import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../../../../../../../config/enums/EnumSteps";

function CreateCheckByTrustiesOfSolution(paramsComponent: InterfaceCreateCheckByTrustiesOfSolution) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getClickInButtonOfParentSubmit, setClickInButtonOfParentSubmit] = useState<{ itemClicked: string; time: number; forWhichStep: string }>({ itemClicked: "", time: -1, forWhichStep: "0.0.0" });
    const [getIsApprovedValue, setIsApprovedValue] = useState<boolean>(false);
    const [getDescriptionValue, setDescriptionValue] = useState<string>('');
    const [getWorkFlowOfThisSolution, setWorkFlowOfThisSolution] = useState<InterfaceNTTWorkflowSolution[]>([]);
    const callApiForCreateStepCheckByTrustiesOfSolution = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.cottage.buyCottage.steps.addSolutions.stepSolution.stepCheckSolutionByTrusties.createCheckSolution;
        let bodyObj = {
            "previous_review": {
                "review_type": getWorkFlowOfThisSolution[getWorkFlowOfThisSolution.length - 1].review_ct,
                "object_id": getWorkFlowOfThisSolution[getWorkFlowOfThisSolution.length - 1].review_id,
            },
            'is_approved': getIsApprovedValue,
            'description': getDescriptionValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoThisSolution.id}/trader-checks/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                paramsComponent.callBackReLoad();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (paramsComponent.clickOnButtonInParent.forWhichStep === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}`) {
            if (paramsComponent.clickOnButtonInParent.time > 0 && paramsComponent.clickOnButtonInParent.time !== getClickInButtonOfParentSubmit.time) {
                callApiForCreateStepCheckByTrustiesOfSolution();
            }
            setClickInButtonOfParentSubmit(paramsComponent.clickOnButtonInParent);
        }
    }, [paramsComponent.clickOnButtonInParent])
    useEffect(() => {
        setWorkFlowOfThisSolution(paramsComponent.workFlowOfThisSolution);
    }, [paramsComponent.workFlowOfThisSolution])
    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'start',
            justifyContent: 'start',
            gap: '1.18rem',
            width: '100%',
            marginTop: '1.18rem',
            boxSizing: 'border-box',
        }}>
            <TextFieldMultiLine
                title={confComps.translate.explanation}
                text={getDescriptionValue}
                onChangeText={setDescriptionValue}
                styleParent={{
                    boxSizing: 'border-box',
                    border: `1px solid ${Colors.theme.blue.border}`,
                    background: Colors.theme.white.white90,
                }}
            />
            <div style={{
                width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center',
                justifyContent: 'end',
                gap: '0.59rem',
            }}>
                <ButtonCancel
                    text={"This solution reject by one of trusties"}
                    callbackOnClick={() => {
                        setIsApprovedValue(false);
                    }}
                    iconStart={<SentimentVeryDissatisfiedRounded
                        style={{
                            fontSize: '1.1rem',
                            // marginBottom: '-5%',
                            color: getIsApprovedValue ? Colors.theme.orange.sharp : Colors.theme.orange.lemonLight,
                        }}
                    />}
                    style={getIsApprovedValue ?
                        {
                            background: "#fff",
                            color: Colors.theme.orange.sharp,
                            borderColor: `${Colors.theme.orange.sharp}`,
                        } :
                        {
                            background: Colors.theme.orange.sharp,
                            color: Colors.theme.orange.lemonLight,
                            borderColor: `${Colors.theme.orange.sharp}`,
                        }
                    }
                />


                <ButtonSave
                    text={"This solution accept by any trusties"}
                    callbackOnClick={() => {
                        setIsApprovedValue(true);
                    }}
                    iconStart={<CelebrationRounded
                        style={{
                            fontSize: '1.1rem',
                            color: !getIsApprovedValue ? Colors.theme.green.dark : Colors.theme.green.lightLemon,
                        }} />
                    }
                    style={!getIsApprovedValue ?
                        {
                            background: "#fff",
                            color: Colors.theme.green.dark,
                            borderColor: `${Colors.theme.green.dark}`,
                        } :
                        {
                            background: Colors.theme.green.dark,
                            color: Colors.theme.green.lightLemon,
                            borderColor: `${Colors.theme.green.dark}`,
                        }
                    }

                />
            </div>
        </div>
    );
}
export default CreateCheckByTrustiesOfSolution;