import { useEffect, useState } from 'react';
import ParentWithHeightAnimation from '../../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation';
import { InterfaceCreateAddSolutionForBuyCottage, InterfaceSolutionForCreator } from './InterfaceAddSolutionForBuyCottage';
import { Box, Grid, useTheme } from '@mui/material';
import TextFieldSimple from '../../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple';
import { ConfigComps } from '../../../../../../../config/ConfigCOMP';
import { InterfaceNTTCurrencyWithTypeAndLocation } from '../../../../../../../NTTs/InterfaceNTTCurrency';
import SelectCurrencyWithTypeAndLocation from '../../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation';
import SubTitleSection from '../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection';
import TextFieldMultiLine from '../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine';
import Colors from '../../../../../../../config/Colors';
import ButtonSave from '../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave';
import { InterfaceNTTRecapMain } from '../../../../../../../NTTs/Recap/InterfaceNTTRecapMain';

import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from '../../../../../../../api/APIErrorResponse';
import { urlsList, HandlerLangUrl, TypeQueryParams } from '../../../../../../../api/UrlsList';
import { enumTypeOfAlert } from '../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup';
import { ShowAlert } from '../../../../../../../store/AlertStateModeSlice';
import { isVisibilityProgressBar } from '../../../../../../../store/ProgressbarInRootSlice';
import { InterfaceGetAPICustomersList } from '../../../../../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList';
import { SelectedProfile } from '../../../../../../../store/profileStateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Links from '../../../../../../../config/Links';
import ButtonCancel from '../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel';
import ShowAllWalletsTheUser from '../../../../../../../components/complex/ShowAllWalletsTheUser/ShowAllWalletsTheUser';
import { AddRounded, DoneRounded, ThumbDownRounded, ThumbUpRounded } from '@mui/icons-material';
import { RequestHandler } from '../../../../../../../api/API';
import ProgressBarGreenToRed from '../../../../../../../components/complex/ProgressBar/ProgressBarGreenToRed/ProgressBarGreenToRed';
import { InterfaceNTTOneStepOfWorkflowBuyCottageRecap } from '../../../../../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneStepOfWorkflowBuyCottageRecap';
import ButtonColor from '../../../../../../../components/basic/Buttons/ButtonColor/ButtonColor';
import { TrashIcon } from '../../../../../../../assets/icons/svg/TrashIcon';
import { InterfaceNTTUser } from '../../../../../../../NTTs/InterfaceNTTUser';

function CreateAddSolutionForBuyCottage(paramsComponent: InterfaceCreateAddSolutionForBuyCottage) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getSolutions, setSolutions] = useState<InterfaceSolutionForCreator[]>([{ texts: [], users: [] }]);
    const [getDescriptionValue, setDescriptionValue] = useState<string>('');
    const [getIsWaitingForRecapMain, setIsWaitingForRecapMain] = useState<boolean>();
    const [getAllWorkflowReviews, setAllWorkflowReviews] = useState<InterfaceNTTOneStepOfWorkflowBuyCottageRecap[]>([]);
    const [textAreasHeight, setTextAreasHeight] = useState(getSolutions.map(() => 'auto'));

    const handleOnClickSubmitButtons = async () => {
        callApiForCreateCommentFinanceForTheCustomer();
        getSolutions.forEach((solution, index) => {
            callApiForAddOneSolution(index, solution.texts, solution.users);
        });

    }
    const addNewEmptySolutions = async () => {
        setSolutions(prev => [...prev, { texts: [], users: [] }]);
    }
    const callApiForAddOneSolution = async (index: number, texts: string[], users: InterfaceNTTUser[]) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.cottage.buyCottage.steps.addSolutions.stepSolution.createOneSolution;
        let bodyObj = {
            "number": index,
            "description_list": texts,
            "user_orders": users.map((us, index) => { return { "user": us.id, "order": index } }),
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoServiceInMainRecap.id}/solutions/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                // let t = response as InterfaceGetAPICustomersList;
                let getOldStateWaiting = getIsWaitingForRecapMain;
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackReLoad();
                paramsComponent.callBackSetWaiting(false);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackSetWaiting(false);
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }


    const callApiForCreateCommentFinanceForTheCustomer = async () => {

        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.cottage.buyCottage.steps.addSolutions.metaData.createSteps;
        let bodyObj = {
            "previous_review": {
                "review_type": getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_ct, // or recapservicefundcontrol
                "object_id": getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_id,
            },
            'description': getDescriptionValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoServiceInMainRecap.id}/meta/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                // let t = response as InterfaceGetAPICustomersList;
                let getOldStateWaiting = getIsWaitingForRecapMain;
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackReLoad();
                paramsComponent.callBackSetWaiting(false);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackSetWaiting(false);
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setAllWorkflowReviews(paramsComponent.allWorkflowReviews);
    }, [paramsComponent.allWorkflowReviews])
    return (
        <ParentWithHeightAnimation in={true}
            loading={getIsWaitingForRecapMain}
            style={{
                boxSizing: 'border-box',
                width: '100%',
            }}
        >
            <Box sx={{
                boxSizing: 'border-box',
                paddingInlineStart: { xs: '0.8rem', sm: '0.9rem', md: '1.0rem', lg: '1.18rem', xl: '1.3rem' },
                paddingInlineEnd: { xs: '0.8rem', sm: '3.33rem', md: '3.7rem', lg: '4.14rem', xl: '6rem' },
                display: 'flex', flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                marginTop: '1.18rem',
            }}>
                <Grid container
                    justifyContent='space-between' alignItems='flex-start'
                    columnSpacing={{ xs: 0, sm: 0, md: '1rem', lg: '1.18rem', xl: '2rem' }}
                    rowSpacing={{ xs: '1.2rem', sm: '1.39rem', md: '1.53rem', lg: '1.70rem', xl: '1.87rem' }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'start',
                            border: `1px solid ${Colors.theme.gray.gray}`,
                            background: Colors.theme.white.white90,
                            borderRadius: '0.37rem',
                            paddingBlock: '0.37rem',
                            boxSizing: 'border-box',
                        }}>
                            {getSolutions.flatMap((solution, index, arr) => {
                                return [
                                    <div style={{
                                        width: '100%',
                                        display: 'flex', flexDirection: 'row',
                                        flexWrap: 'nowrap',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        boxSizing: 'border-box',
                                        paddingInline: "0.88rem",
                                        marginBlockEnd: '0.4rem',
                                    }}>
                                        <p style={{
                                            display: 'inline-flex', flexDirection: 'row',
                                            alignItems: 'start',
                                            justifyContent: 'start',
                                            flexGrow: 0,
                                            flexBasis: '2rem',
                                            fontSize: '1.03rem',
                                            lineHeight: '1.55rem',
                                            flexWrap: 'nowrap',
                                        }}>
                                            {`${index + 1} :`}
                                        </p>
                                        <textarea
                                            // onFocus={() => setIsFocused(true)}
                                            // onBlur={() => setIsFocused(false)}
                                            value={solution.texts}
                                            rows={1}
                                            placeholder={'write new solution'}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                // Create a new array of solutions with the updated text
                                                const newValue = e.target.value;
                                                const updatedSolutions = getSolutions.map((item, i) => {
                                                    if (i === index) {
                                                        return { ...item, texts: [newValue] };
                                                    }
                                                    return item;
                                                });
                                                setSolutions(updatedSolutions);

                                                // Adjust the height of the textarea
                                                const textAreaElement = e.target;
                                                textAreaElement.style.height = 'auto'; // Reset height to auto for recalculating
                                                textAreaElement.style.height = `${textAreaElement.scrollHeight}px`; // Set new height

                                                // Update the state for the height
                                                setTextAreasHeight((prevHeights) => {
                                                    const newHeights = [...prevHeights];
                                                    newHeights[index] = `${textAreaElement.scrollHeight}px`;
                                                    return newHeights;
                                                });
                                            }}
                                            style={{
                                                flexGrow: 1,
                                                flexBasis: 0,
                                                resize: 'none', // Prevent manual resizing by user
                                                boxSizing: "border-box",
                                                width: "100%",
                                                alignItems: 'center',
                                                color: Colors.theme.blue.dark,
                                                textDecoration: "none",
                                                fontFamily: confComps.font.poppins.Regular,
                                                fontSize: "0.96rem",
                                                outline: "none",
                                                border: "none",
                                                height: textAreasHeight[index], // Dynamically set height
                                            }}
                                        />
                                        <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                            onClick={() => {
                                                const updatedSolutions = getSolutions.filter((_, i) => i !== index);
                                                setSolutions(updatedSolutions);

                                                // Update the height state
                                                setTextAreasHeight((prevHeights) => {
                                                    return prevHeights.filter((_, i) => i !== index);
                                                });
                                            }}>
                                            <TrashIcon style={{ marginInlineStart: '0.58rem' }} />
                                        </div>
                                    </div>,
                                    <div style={{
                                        width: '100%',
                                        height: '1px',
                                        borderRadius: '10px',
                                        background: '#E2E4EC',
                                    }} />
                                ]
                            })}
                            <div style={{//add button design
                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                justifyContent: 'start',
                                width: '100%',
                                paddingInline: '0.88rem',
                                boxSizing: 'border-box',

                            }}>
                                <div style={{
                                    display: 'inline-flex', flexDirection: 'row',
                                    alignItems: 'center', justifyContent: 'start',
                                    fontFamily: confComps.font.poppins.Medium,
                                    fontSize: '0.88rem',
                                    lineHeight: '1.33rem',
                                    color: Colors.theme.orange.light,
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                }}
                                    onClick={addNewEmptySolutions}
                                >
                                    <AddRounded style={{
                                        color: Colors.theme.orange.light, fontSize: '0.88rem',
                                        userSelect: 'none',
                                    }} />

                                    {confComps.translate.addSolution}


                                </div>

                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextFieldMultiLine
                            title={confComps.translate.explanation}
                            text={getDescriptionValue}
                            onChangeText={setDescriptionValue}
                            styleParent={{
                                boxSizing: 'border-box',
                                border: `1px solid ${Colors.theme.blue.border}`,
                                background: Colors.theme.white.white90,
                            }}
                        />
                    </Grid>
                </Grid>

            </Box>
            <div style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'end',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '1.18rem',
                gap: '0.88rem',
            }}>
                <ButtonSave
                    text={confComps.translate.submit}
                    callbackOnClick={() => {
                        handleOnClickSubmitButtons();
                    }}
                    iconStart={<DoneRounded
                        style={{
                            fontSize: '1.1rem',
                        }} />
                    }
                />

            </div>
        </ParentWithHeightAnimation>
    )

}
export default CreateAddSolutionForBuyCottage;