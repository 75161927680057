import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import AdvanceSearchBox from "../../../../components/complex/advanceSearchBox/AdvanceSearchBox";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { interfaceReceiptListPage } from "./InterfaceReceiptListPage";
import ReceiptItem from "./receiptItem/ReceiptItem";

function ReceiptListPage(paramsPage: interfaceReceiptListPage) {
    var confComps = ConfigComps();
    return (
        <ContainerPage title={confComps.panel.services.receipt.header.title}>
            <div style={{
                width: "100%", display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            }}>
                <AdvanceSearchBox defaultValue={{ smartSearch: "", groupList: [], advanceBox: { dateItem: [], numbersItem: [], relationIDsItem: [], stringsItem: [] } }}
                    doneSearching={() => {
                    }}
                />
            </div>
            <div style={{
                display: "flex", flexDirection: "column", width: "100%",
                boxSizing: "border-box",
            }}>
                <ReceiptItem
                    amount={"12000"} country={"US"} unitValue={"Dollar"} state={"completed"} name={"Alireza"}
                    family={"safafard"} date={"2023/04/03"} file={"file_name1.png"}
                />

            </div>



        </ContainerPage>
    )
}
export default ReceiptListPage;