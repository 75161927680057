import { Recommend } from "@mui/icons-material";
import { Theme, useTheme } from "@mui/material/styles";;

// Define SupportedMethods type as filter type of methods axios use case.
export enum SupportedMethods {
    get = "get",
    post = "post",
    put = "put",
    delete = "delete",
    patch = "patch"
};
export const baseUrl = "https://nesta-api.niktech.org"
export const wsBaseUrl = "wss://nesta-api.niktech.org/ws"
// export const baseUrl = "https://tb-api.niktech.org"
// export const wsBaseUrl = "wss://tb-api.niktech.org/ws"
// Put your new endpoint like here.
export type TypeQueryParams = {
    [key: string]: string | number | boolean;
};
export const HandlerLangUrl = (url: string, mTheme: any, params?: TypeQueryParams): string => {
    if (params) {
        const searchParams = new URLSearchParams(
            Object.entries(params).map(([key, value]) => [key, String(value)])
        );
        return (`${baseUrl}${mTheme.direction === "rtl" ? "/fa" : "/en"}${url}?${searchParams.toString()}`)
    }
    else {
        return (`${baseUrl}${mTheme.direction === "rtl" ? "/fa" : "/en"}${url}`)
    }
}
export interface interfaceUrl {
    url: string;
    method: SupportedMethods;
    isTokenNecessary: boolean;
    isFileInclude: boolean;

}
export const urlsList = {

    auth: {
        login: {
            login: { url: `/users/employees/login/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        },
        register: {
            generateOTP: { url: `/users/auth/generate-otp/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },

        },
        refreshToken: { url: `/accounts/users/auth/refresh/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        setPassword: { url: `/users/auth/set-password/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },

    },
    panel: {
        recap: {
            getTrustyAndCustomer: { url: `/users/customers/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            getAllRecaps: { url: `/recaps/list/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            postCreateRecapMainInitiate: { url: `/recaps/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            updateRecapInitiate: { url: `/recaps/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            getRecapInitiate: { url: `/recaps/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getAllWorkFlowOfRecap: { url: `/recaps/steps/workflow-reviews/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            MainServices: {
                moneyReceive: {
                    createMoneyReceive: { url: `/recaps/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                    steps: {
                        foundControlCheckWalletTheCustomer: {
                            createSteps: { url: `/recaps/steps/fund-control/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                            getInfoThisStep: { url: `/recaps/steps/fund-control/services/fund-controls/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                        },
                        riskMeasurementTheCustomer: {
                            createSteps: { url: `/recaps/steps/compliance/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                            getInfoThisStep: { url: `/recaps/steps/compliance/services/compliances/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                        },
                        addSolutions: {
                            metaData: {
                                createSteps: { url: `/recaps/steps/add-solution/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                riskMeasurementSolution: {
                                    create: { url: `/recaps/steps/solution-risk/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getInfo: { url: `/recaps/steps/solution-risk/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                CheckWalletOfTrustiesSolution: {
                                    create: { url: `/recaps/steps/finance-check/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getInfo: { url: `/recaps/steps/finance-check/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                CheckByTrustiesSolution: {
                                    create: { url: `/recaps/steps/trader-check/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getInfo: { url: `/recaps/steps/trader-check/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                getInfoThisStep: { url: `/recaps/steps/add-solution/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                            stepSolution: {
                                createOneSolution: { url: `/recaps/steps/add-solution/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                stepRiskMeasurement: {
                                    createRiskMeasurementSolution: { url: `/recaps/steps/solution-risk/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getRiskMeasurementSolution: { url: `/recaps/steps/solution-risk/services/solutions/solution-risks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                stepCheckWalletOfTrusties: {
                                    createCheckWallet: { url: `/recaps/steps/finance-check/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getCheckWalletOfTrustiesForSolution: { url: `/recaps/steps/finance-check/services/solutions/finance-checks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                stepCheckSolutionByTrusties: {
                                    createCheckSolution: { url: `/recaps/steps/trader-check/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getInfo: { url: `/recaps/steps/trader-check/services/solutions/trader-checks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                getListSolutions: { url: `/recaps/steps/add-solution/receipts/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                getWorkFlowSolution: { url: `/recaps/steps/solutions/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                        },
                        chooseOneSolution: {
                            createSteps: { url: `/recaps/steps/choose-solution/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                            getInfoThisStep: { url: `/recaps/steps/choose-solution/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                        },
                        approvalSolutionForThis: {
                            createSteps: { url: `/recaps/steps/approval/receipts/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                            getInfoThisStep: { url: `/recaps/steps/approval/services/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                        },
                    }
                },
                moneyPayment: {
                    createMoneyPayment: { url: `/recaps/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                    steps: {
                        foundControlCheckWalletTheCustomer: {
                            createSteps: { url: `/recaps/steps/fund-control/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                            getInfoThisStep: { url: `/recaps/steps/fund-control/services/fund-controls/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                        },
                        riskMeasurementTheCustomer: {
                            createSteps: { url: `/recaps/steps/compliance/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                            getInfoThisStep: { url: `/recaps/steps/compliance/services/compliances/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                        },
                        addSolutions: {
                            metaData: {
                                createSteps: { url: `/recaps/steps/add-solution/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                riskMeasurementSolution: {
                                    create: { url: `/recaps/steps/solution-risk/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getInfo: { url: `/recaps/steps/solution-risk/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                CheckWalletOfTrustiesSolution: {
                                    create: { url: `/recaps/steps/finance-check/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getInfo: { url: `/recaps/steps/finance-check/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                CheckByTrustiesSolution: {
                                    create: { url: `/recaps/steps/trader-check/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getInfo: { url: `/recaps/steps/trader-check/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                getInfoThisStep: { url: `/recaps/steps/add-solution/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                            stepSolution: {
                                createOneSolution: { url: `/recaps/steps/add-solution/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                stepRiskMeasurement: {
                                    createRiskMeasurementSolution: { url: `/recaps/steps/solution-risk/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getRiskMeasurementSolution: { url: `/recaps/steps/solution-risk/services/solutions/solution-risks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                stepCheckWalletOfTrusties: {
                                    createCheckWallet: { url: `/recaps/steps/finance-check/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getCheckWalletOfTrustiesForSolution: { url: `/recaps/steps/finance-check/services/solutions/finance-checks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                stepCheckSolutionByTrusties: {
                                    createCheckSolution: { url: `/recaps/steps/trader-check/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    getInfo: { url: `/recaps/steps/trader-check/services/solutions/trader-checks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                getListSolutions: { url: `/recaps/steps/add-solution/payments/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                getWorkFlowSolution: { url: `/recaps/steps/solutions/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                        },
                        chooseOneSolution: {
                            createSteps: { url: `/recaps/steps/choose-solution/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                            getInfoThisStep: { url: `/recaps/steps/choose-solution/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                        },
                        approvalSolutionForThis: {
                            createSteps: { url: `/recaps/steps/approval/payments/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                            getInfoThisStep: { url: `/recaps/steps/approval/services/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                        },
                    }
                },
                cottage: {
                    getAllGoodsCategory: { url: `/recaps/cottage-categories/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                    buyCottage: {
                        createBuyCottage: { url: `/recaps/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                        steps: {
                            foundControlCheckWalletTheCustomer: {
                                createSteps: { url: `/recaps/steps/fund-control/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                getInfoThisStep: { url: `/recaps/steps/fund-control/services/fund-controls/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                            riskMeasurementTheCustomer: {
                                createSteps: { url: `/recaps/steps/compliance/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                getInfoThisStep: { url: `/recaps/steps/compliance/services/compliances/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                            addSolutions: {
                                metaData: {
                                    createSteps: { url: `/recaps/steps/add-solution/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    riskMeasurementSolution: {
                                        create: { url: `/recaps/steps/solution-risk/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getInfo: { url: `/recaps/steps/solution-risk/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    CheckWalletOfTrustiesSolution: {
                                        create: { url: `/recaps/steps/finance-check/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getInfo: { url: `/recaps/steps/finance-check/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    CheckByTrustiesSolution: {
                                        create: { url: `/recaps/steps/trader-check/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getInfo: { url: `/recaps/steps/trader-check/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    getInfoThisStep: { url: `/recaps/steps/add-solution/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                stepSolution: {
                                    createOneSolution: { url: `/recaps/steps/add-solution/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    stepRiskMeasurement: {
                                        createRiskMeasurementSolution: { url: `/recaps/steps/solution-risk/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getRiskMeasurementSolution: { url: `/recaps/steps/solution-risk/services/solutions/solution-risks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    stepCheckWalletOfTrusties: {
                                        createCheckWallet: { url: `/recaps/steps/finance-check/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getCheckWalletOfTrustiesForSolution: { url: `/recaps/steps/finance-check/services/solutions/finance-checks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    stepCheckSolutionByTrusties: {
                                        createCheckSolution: { url: `/recaps/steps/trader-check/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getInfo: { url: `/recaps/steps/trader-check/services/solutions/trader-checks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    getListSolutions: { url: `/recaps/steps/add-solution/buy-cottages/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    getWorkFlowSolution: { url: `/recaps/steps/solutions/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                            },
                            chooseOneSolution: {
                                createSteps: { url: `/recaps/steps/choose-solution/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                getInfoThisStep: { url: `/recaps/steps/choose-solution/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                            approvalSolutionForThis: {
                                createSteps: { url: `/recaps/steps/approval/buy-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                getInfoThisStep: { url: `/recaps/steps/approval/services/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                        }
                    },
                    sellCottage: {
                        createSellCottage: { url: `/recaps/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                        steps: {
                            foundControlCheckWalletTheCustomer: {
                                createSteps: { url: `/recaps/steps/fund-control/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                getInfoThisStep: { url: `/recaps/steps/fund-control/services/fund-controls/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                            riskMeasurementTheCustomer: {
                                createSteps: { url: `/recaps/steps/compliance/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                getInfoThisStep: { url: `/recaps/steps/compliance/services/compliances/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                            addSolutions: {
                                metaData: {
                                    createSteps: { url: `/recaps/steps/add-solution/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    riskMeasurementSolution: {
                                        create: { url: `/recaps/steps/solution-risk/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getInfo: { url: `/recaps/steps/solution-risk/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    CheckWalletOfTrustiesSolution: {
                                        create: { url: `/recaps/steps/finance-check/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getInfo: { url: `/recaps/steps/finance-check/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    CheckByTrustiesSolution: {
                                        create: { url: `/recaps/steps/trader-check/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getInfo: { url: `/recaps/steps/trader-check/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    getInfoThisStep: { url: `/recaps/steps/add-solution/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                                stepSolution: {
                                    createOneSolution: { url: `/recaps/steps/add-solution/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                    stepRiskMeasurement: {
                                        createRiskMeasurementSolution: { url: `/recaps/steps/solution-risk/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getRiskMeasurementSolution: { url: `/recaps/steps/solution-risk/services/solutions/solution-risks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    stepCheckWalletOfTrusties: {
                                        createCheckWallet: { url: `/recaps/steps/finance-check/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getCheckWalletOfTrustiesForSolution: { url: `/recaps/steps/finance-check/services/solutions/finance-checks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    stepCheckSolutionByTrusties: {
                                        createCheckSolution: { url: `/recaps/steps/trader-check/services/solutions/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                        getInfo: { url: `/recaps/steps/trader-check/services/solutions/trader-checks/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    },
                                    getListSolutions: { url: `/recaps/steps/add-solution/sell-cottages/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                    getWorkFlowSolution: { url: `/recaps/steps/solutions/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                                },
                            },
                            chooseOneSolution: {
                                createSteps: { url: `/recaps/steps/choose-solution/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                getInfoThisStep: { url: `/recaps/steps/choose-solution/services/meta/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                            approvalSolutionForThis: {
                                createSteps: { url: `/recaps/steps/approval/sell-cottages/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                                getInfoThisStep: { url: `/recaps/steps/approval/services/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                            },
                        }
                    },
                }
            },
        },
        wallet: {
            nestaWallet: {
                postCreateNewNestaWallet: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                deleteNestaWallet: { url: `/users/customers-trusties/nesta-wallets/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                getAllNestaWallets: { url: `/users/customers-trusties/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            }
        },
        cashAccount: {
            postCreateNewCashAccount: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            deleteCashAccount: { url: `/users/customers-trusties/cash-accounts/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
            getAllCashAccounts: { url: `/users/customers-trusties/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            patchCashAccount: { url: `/users/customers-trusties/cash-accounts/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
        },
        customer: {
            deleteTheCustomer: { url: `/users/customers-trusties/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
            getCustomerList: { url: `/users/customers/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            updateCustomerList: { url: `/users/customers-trusties/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            getTheCustomerInfo: { url: `/users/customers/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            postAddCustomer: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            postAddProfileImageCustomer: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: true },
        },
        trusty: {
            addProfilePicture: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: true },
            deleteTheCustomer: { url: `/users/customers-trusties/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
            getTrustyList: { url: `/users/trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            updateCustomerList: { url: `/users/customers-trusties/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            getTheTrustyInfo: { url: `/users/trusties/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            postAddTrusty: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            postAddProfileImageTrusty: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: true },
        },
        employee: {
            updateTheEmployeeProfile: { url: `/users/employees/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            deleteTheEmployee: { url: `/users/employees/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
            getEmployeesList: { url: `/users/employees/list/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            postAddEmployee: { url: `/users/employees/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            postAddProfileImageEmployee: { url: `/users/employees/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: true },
            getTheEmployeeInfo: { url: `/users/employees/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getRoleOfEmployee: { url: `/users/employees/roles/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        },
        myProfile: {
            postUploadProfilePicture: { url: `/users/info/profile-pic/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: true },
            getInformationOfMyProfile: { url: `/users/info/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            updateInformationOfMyProfile: { url: `/users/info/update/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            changeMyPassword: { url: `/users/info/change-password/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
        },
        common: {
            activity: {
                getActivity: { url: `/users/activities/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            }
        },
        location: {
            getCountry: { url: `/users/countries/?page=1&page_size=20&search=`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getCity: { url: `/users/cities/?page=1&page_size=20&search=`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        },
        company: {
            getAllCompanyTypeOwnerShipStructure: { url: `/users/customers-trusties/domestic-legal-profiles/company-types/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            postCreateNewDomesticCompany: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            patchUpdateDomesticCompany: { url: `/users/customers-trusties/domestic-companies/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            postAddFileToDomesticCompany: { url: `/users/customers-trusties/domestic-companies/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: true },
            postGetAllDomesticCompany: { url: `/users/customers-trusties/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            deleteDomesticCompany: { url: `/users/customers-trusties/domestic-companies/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
            deleteDocFromDomesticCompany: { url: `/users/customers-trusties/domestic-companies/documents/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },

            foreignCompany: {
                postGetAllForeignCompany: { url: `/users/customers-trusties/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                postCreateNewForeignCompany: { url: `/users/customers-trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                patchUpdateForeignCompany: { url: `/users/customers-trusties/foreign-companies/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
                postAddFileToForeignCompany: { url: `/users/customers-trusties/foreign-companies/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: true },
                deleteForeignCompany: { url: `/users/customers-trusties/foreign-companies/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                deleteDocFromForeignCompany: { url: `/users/customers-trusties/foreign-companies/documents/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
            }
        },
        foreignBankAccount: {
            postAddBankAccount: { url: `/users/customers-trusties/foreign-companies/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            getAllBankAccount: { url: `/users/customers-trusties/foreign-companies/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            deleteTheBankAccount: { url: `/users/customers-trusties/foreign-companies/bank-accounts/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
            updateTheBankAccount: { url: `/users/customers-trusties/foreign-companies/bank-accounts/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },

        },
        currency: {
            getCurrenciesType: { url: `/users/currencies/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getCurrenciesWidthTypeAndLocation: { url: `/users/customers-trusties/currency-units/?search=`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        },
        rate: {
            changeRates: {
                postAddChangeRate: { url: `/users/trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                getAllChangeRate: { url: `/users/trusties/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                deleteTheChangeRate: { url: `/users/trusties/change-rates/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                updateTheChangeRate: { url: `/users/trusties/change-rates/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            },
            receptionRate: {
                postAddReceptionRate: { url: `/users/trusties/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                getAllReceptionRate: { url: `/users/trusties/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                deleteTheReceptionRate: { url: `/users/trusties/reception-rates/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                updateTheReceptionRate: { url: `/users/trusties/reception-rates/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
            }
        },
        recommendation: {
            recommended: {
                createRecommended: { url: `/users/recommendations/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                deleteRecommended: { url: `/users/recommendations/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                getAllRecommended: { url: `/users/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            },
            recommender: {
                createRecommender: { url: `/users/recommendations/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                deleteRecommender: { url: `/users/recommendations/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                getAllRecommender: { url: `/users/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            }
        },
        user: {
            getAllUser: { url: `/users/search/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        }
    }
};