import { useEffect, useRef, useState } from "react";
import { InterfaceAdvanceBox, InterfaceAdvanceSearchBox, InterfaceGroupHeader } from "./InterfaceAdvanceSearchBox";
import { ConfigComps } from "../../../config/ConfigCOMP";
import Colors from "../../../config/Colors";
import { SearchIcon } from "../../../assets/icons/svg/SearchIcon";
import { AdvanceBoxParent, AdvanceItemInputOfNumberMoneyType, AdvanceItemInputOfStringType, AdvanceItemOfNumberMoneyType, AdvanceItemTitleOfStringType, SearchBoxParent } from "./AdvanceSearchBoxStyle";
import ParentWithWidthAnimation from "../../basic/animation/ParentWithWidthAnimation/ParentWithWidthAnimation";
import { CancelRounded, CheckCircleRounded, CircleOutlined, DoneRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import DatePickerMUIWithUnderLine from "../../basic/Dates/DatePickerMUIWithUnderLine/DatePickerMUIWithUnderLine";
import DatePickerSimple from "../../basic/Dates/DatePickerSimple/DatePickerSimple";
import { InterfaceNTTRoleEmployee } from "../../../NTTs/InterfaceNTTRoleEmployee";
import TextFieldSelectorRolesEmployeeSimple from "../../basic/TextField/RoleEmployee/TextFieldSelectorRolesEmployeeSimple/TextFieldSelectorRolesEmployeeSimple";
import TextFieldMultiSelectorActivitiesJobSimple from "../../basic/TextField/ActivitiesJob/TextFieldMultiSelectorActivitiesJobSimple/TextFieldMultiSelectorActivitiesJobSimple";
import { ImageSquare } from "../imageSquare/ImageSquare";
import AllChildrenOfAdvanceBoxGroup from "./AllChildrenOfAdvanceBoxGroup/AllChildrenOfAdvanceBoxGroup";
import ParentWithHeightAnimation from "../../basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
export enum EnumItemThatHandleInSmartSearch {
    role = "role",
    activitiesJob = "activities"

}
function AdvanceSearchBox(componentsParams: InterfaceAdvanceSearchBox) {
    var confComps = ConfigComps();
    const [getSmartSearch, setSmartSearch] = useState<string>(componentsParams.defaultValue.smartSearch ?? "");
    const [getAllItemsOfAdvanceParams, setAllItemsOfAdvanceParams] = useState<{
        advanceBox: InterfaceAdvanceBox;
        groupList: InterfaceGroupHeader[];
    }>(componentsParams.defaultValue);
    const [getIsAdvancedBoxOpen, setIsAdvancedBoxOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSmartSearch(e.target.value);
    };
    const handleFocus = () => {
        setIsFocused(true);
        // componentsParams.onFocus && componentsParams.onFocus();
    };
    useEffect(() => {
        if (isFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                let allDateItems = [...getAllItemsOfAdvanceParams.advanceBox.dateItem, ...getAllItemsOfAdvanceParams.groupList.flatMap(group => group.advanceBox.dateItem)];
                if (containerRef.current && !containerRef.current.contains(event.target as Node)
                    && allDateItems.filter(d => d.isDialogOpenStart === true || d.isDialogOpenEnd === true).length === 0) {
                    setIsFocused(false);
                    setIsAdvancedBoxOpen(false);
                    // componentsParams.onBlur && componentsParams.onBlur();
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [isFocused]);//handle out side click for close dropdown box
    return (
        <div
            style={{
                display: "flex", flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                flexGrow: "1",
                flexBasis: "0",
                flex:1,
                position: "relative",
                boxSizing: "border-box",

            }}
            ref={containerRef}
        >
            <SearchBoxParent
                isDropdownOpen={getIsAdvancedBoxOpen}
                onClick={handleFocus}
            >
                <div style={{
                    display: "flex", flexDirection: "row", alignItems: "center",
                    justifyContent: "start",
                    flexGrow: "1",
                    gap: getSmartSearch !== (componentsParams.defaultValue.smartSearch ?? "") ? "8px" : undefined,
                    position: "relative",
                }}>
                    <ParentWithWidthAnimation in={getSmartSearch !== (componentsParams.defaultValue.smartSearch ?? "")} timeout={300} style={{ gap: "0.5rem" }}>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsAdvancedBoxOpen(false);
                                componentsParams.doneSearching({ smartSearch: getSmartSearch, ...getAllItemsOfAdvanceParams })
                            }}
                            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                        >
                            <SearchIcon
                                style={{
                                    color: isFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    minWidth: 17,
                                    cursor: "pointer"
                                }}
                            />
                        </div>
                    </ParentWithWidthAnimation>

                    <input
                        autoComplete="off"
                        style={{
                            color: Colors.theme.blue.dark,
                            flexGrow: "1",
                        }}
                        type="text"
                        value={getSmartSearch}
                        onChange={handleInputChange}
                        placeholder={confComps.component.advanceSearchBox.hintText}
                    />
                </div>

                <ParentWithWidthAnimation in={(!getIsAdvancedBoxOpen)} timeout={300}
                    style={{
                        // background: "red",
                        marginInlineEnd: !getIsAdvancedBoxOpen ? "-4px" : undefined
                    }}
                >
                    <button onClick={() => setIsAdvancedBoxOpen(!getIsAdvancedBoxOpen)}
                        style={{
                            color: getIsAdvancedBoxOpen ? Colors.theme.blue.blue : Colors.theme.blue.light,
                            fontWeight: "500",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                        }}>
                        {confComps.component.advanceSearchBox.buttonAdvance}

                        <KeyboardArrowDownRounded
                            style={{
                                fontSize: "1.9rem",

                                color: Colors.theme.blue.dark,
                                cursor: "pointer",
                                transform: getIsAdvancedBoxOpen ? "rotate(180deg)" : "rotate(0deg)",
                                opacity: getIsAdvancedBoxOpen ? "0" : "1",
                                transition: "all 0.5s ease-in-out",
                            }}
                        />
                    </button>
                </ParentWithWidthAnimation>
                <ParentWithWidthAnimation in={getIsAdvancedBoxOpen} timeout={300} style={{ gap: "0.5rem" }}>
                    <CancelRounded
                        style={{
                            color: Colors.theme.orange.sharp,
                            cursor: "pointer",
                        }}
                        onClick={(event) => {
                            setAllItemsOfAdvanceParams(componentsParams.defaultValue);
                            event.stopPropagation();
                            setIsAdvancedBoxOpen(false);
                        }}
                    />
                    <CheckCircleRounded
                        style={{
                            color: Colors.theme.green.dark,
                            cursor: "pointer",
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsAdvancedBoxOpen(false);
                            componentsParams.doneSearching({ smartSearch: getSmartSearch, ...getAllItemsOfAdvanceParams });
                        }}
                    />
                </ParentWithWidthAnimation>


                <AdvanceBoxParent
                    isDropdownOpen={getIsAdvancedBoxOpen}
                >
                    <AllChildrenOfAdvanceBoxGroup
                        defaultValue={getAllItemsOfAdvanceParams.advanceBox}
                        callbackOnChangeValue={function (params: InterfaceAdvanceBox): void {
                            setAllItemsOfAdvanceParams(prev => {
                                return {
                                    ...prev,
                                    advanceBox: params,
                                }
                            })
                        }}
                    />
                    {getAllItemsOfAdvanceParams.groupList.map((group, index, arr) => {
                        return (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'start',
                                width: '100%',
                                paddingInline: '1rem',
                                boxSizing: 'border-box',

                            }}>
                                <div
                                    style={{
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                        justifyContent: 'space-between',
                                        boxSizing: "border-box",
                                        width: '100%',
                                    }}
                                    onClick={() => {
                                        if (group.isSelectedInFilter) {
                                            setAllItemsOfAdvanceParams(prev => {
                                                const updatedParams = prev.groupList.map((param, i) =>
                                                    i === index ?
                                                        {
                                                            ...param,
                                                            isSelectedInFilter: false,
                                                        } as InterfaceGroupHeader
                                                        :
                                                        {
                                                            ...param,
                                                            isSelectedInFilter: false,
                                                        }
                                                );
                                                return {
                                                    groupList: updatedParams,
                                                    advanceBox: prev.advanceBox,
                                                }
                                            });
                                        }
                                        else {
                                            setAllItemsOfAdvanceParams(prev => {
                                                const updatedParams = prev.groupList.map((param, i) =>
                                                    i === index ?
                                                        {
                                                            ...param,
                                                            isSelectedInFilter: true,
                                                        } as InterfaceGroupHeader
                                                        :
                                                        {
                                                            ...param,
                                                            isSelectedInFilter: false,
                                                        }
                                                );
                                                return {
                                                    groupList: updatedParams,
                                                    advanceBox: prev.advanceBox,
                                                }
                                            });
                                        }
                                    }}
                                >
                                    <p style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'start',
                                        fontFamily: confComps.font.poppins.Medium,
                                        fontSize: '1.2rem',
                                        fontWeight: '400',
                                        color: Colors.theme.blue.light,
                                        // textDecoration: 'underline',
                                        borderBottom: group.isSelectedInFilter ?
                                            `1px solid ${Colors.theme.blue.light}`
                                            : undefined,
                                        lineHeight: '1.4rem',
                                        textAlign: 'start',
                                    }}>
                                        {group.title}
                                    </p>

                                    <div
                                        style={{
                                            cursor: "pointer",
                                            display: 'inline-flex',
                                        }}
                                    >
                                        {group.isSelectedInFilter ?
                                            <DoneRounded style={{
                                                color: "#fff", background: Colors.theme.blue.blue,
                                                borderRadius: "500px", fontSize: "1.5rem",
                                                padding: "0.15rem",
                                            }} /> :
                                            <CircleOutlined style={{ color: Colors.theme.blue.blue, fontSize: "1.8rem" }} />
                                        }
                                    </div>

                                </div>
                                <ParentWithHeightAnimation
                                    in={group.isSelectedInFilter}
                                    style={{ width: '100%' }}
                                >
                                    <AllChildrenOfAdvanceBoxGroup
                                        defaultValue={group.advanceBox}
                                        callbackOnChangeValue={function (params: InterfaceAdvanceBox): void {
                                            setAllItemsOfAdvanceParams(prev => {
                                                const updatedParams = prev.groupList.map((param, i) =>
                                                    i === index ?
                                                        {
                                                            ...param,
                                                            advanceBox: params,
                                                        } as InterfaceGroupHeader
                                                        :
                                                        param
                                                ) as InterfaceGroupHeader[];
                                                return {
                                                    groupList: updatedParams,
                                                    advanceBox: prev.advanceBox,

                                                    // ...prev,

                                                    // advanceBox: params,
                                                }
                                            })
                                        }}
                                    />
                                </ParentWithHeightAnimation>
                            </div>
                        )
                    })}

                </AdvanceBoxParent>
            </SearchBoxParent>
        </div>
    )
}
export default AdvanceSearchBox;